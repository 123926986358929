import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRevisionMotoMarcas } from '../../../../store/marcas/revisionMotoMarcasSelectors'
import { DatosMarcaFormErrors } from '../../DatosMarcaForm'
import SpinnerWithText from '../../../../../../common/components/spinnerWithText/SpinnerWithText'
import { t } from '../../../../../../common/i18n'
import styles from '../../datosMarcaForm.module.scss'
import InputDropDown from '../../../../../../shared/components/Input/InputDropDown'
import useMarcasPrincipales from './useMarcasPrincipales'

interface Props {
  cilindrada: string
  handleChange: (marca: number) => void
  register: (instance: never) => void
  errors: DatosMarcaFormErrors
  labelAbove?: boolean
}

export default function Marca({
  handleChange,
  register,
  errors,
  ...rest
}: Props) {
  useDispatch()
  const { loading, marcas, error } = useSelector(selectRevisionMotoMarcas)
  if (error) {
    return <>Error al cargar las marcas</>
  }

  const onChange = (event) => {
    handleChange(event ? event.value : null)
  }

  const { options } = useMarcasPrincipales({ loading, marcas, error })

  return (
    <div className={styles.datosMarcaForm__marca}>
      {loading || !marcas ? (
        <SpinnerWithText
          loading={loading}
          text={t('revisiones.step1_waiting')}
        />
      ) : (
        <>
          <InputDropDown
            placeholder={t('revision_moto.selecciona_marca')}
            labelId="selector-moto.marca"
            name="idSiteMarcaMoto"
            formRef={register}
            errors={errors}
            options={options}
            onChange={onChange}
            required
            searchable={true}
            {...rest}
          />
        </>
      )}
    </div>
  )
}
