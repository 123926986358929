import React from 'react'
import styles from './precio.module.scss'
import { intl, t } from '../../../../../common/i18n'
import { translateCilindrada } from '../../translatedCilindrada.util'
import { CilindradaEnum } from '../../DatosMotoForm'

interface PrecioProps {
  precio: number
  cilindrada: keyof typeof CilindradaEnum
}

export default function Precio({ precio, cilindrada }: Readonly<PrecioProps>) {
  const translatedCilindrada = translateCilindrada(cilindrada).toLowerCase()

  return (
    <div className={styles.revisionMotoPrice}>
      <div className={styles.revisionMotoPrice__text}>
        <h4>{t('revision_moto.precio.title')}</h4>
        <div className={styles.revisionMotoPrice__text_specific}>
          {t('revision_moto.precio.text', {
            cilindrada: translatedCilindrada,
          })}
        </div>
      </div>
      <div>
        <div className={styles.revisionMotoPrice__value}>
          {intl.formatNumber(precio, {
            style: 'currency',
            currency: 'EUR',
          })}
        </div>
        <div className={styles.revisionMotoPrice__iva_text}>
          {t('revision_moto.precio.iva_incluido')}
        </div>
      </div>
    </div>
  )
}
