import React from 'react'
import styles from './proceso.module.scss'
import Moto from './assets/moto-gris.svg'
import Ubicacion from './assets/ubicacion-gris.svg'
import Calendario from './assets/calendario-gris.svg'
import { t } from '../../../../../common/i18n'

const Proceso = () => (
  <div className={styles.procesoCard_container}>
    <h4>{t('revision_moto.proceso.title')}</h4>
    <div className={styles.procesoCard_item}>
      <Moto />
      <div className={styles.procesoCard_item_text}>
        {t('revision_moto.proceso.moto_1')}
        <br />
        {t('revision_moto.proceso.moto_2')}
        <br />
        {t('revision_moto.proceso.moto_3')}
      </div>
    </div>
    <div className={styles.procesoCard_item}>
      <Ubicacion />
      <div className={styles.procesoCard_item_text}>
        {t('revision_moto.proceso.elige_taller')}
      </div>
    </div>
    <div className={styles.procesoCard_item}>
      <Calendario />
      <div className={styles.procesoCard_item_text}>
        {t('revision_moto.proceso.selecciona_fecha')}
      </div>
    </div>
  </div>
)

export default Proceso
