import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useForm from 'react-hook-form'
import styles from './moto.module.scss'
import Marca from './marca/marca'
import Modelo, {
  RevisionMotoGroupedModelo,
  RevisionMotoModeloMoto,
} from './modelo/modelo'
import Any from './any/any'
import { PostRevisionLead } from '../../../api/postRevisionLead'
import {
  revisionLead,
  revisionLeadReset,
} from '../../../store/lead/revisionMotoLeadAction'
import { selectRevisionMotoIdLead } from '../../../store/matricula/revisionMotoMatriculaSelectors'
import SelectorFooterDesktop from '../../../../../common/components/FunnelSelector/Footers/SelectorFooterDesktop'
import { revisionMarcasReset } from '../../../store/marcas/revisionMotoMarcasAction'
import { t } from '../../../../../common/i18n'
import { revisionDatosReset } from '../../../store/matricula/revisionMotoMatriculaAction'
import {
  revisionModelos,
  revisionModelosReset,
} from '../../../store/modelos/revisionMotoModelosAction'
import { CilindradaEnum } from '../../DatosMotoForm'
import { translateCilindrada } from '../../translatedCilindrada.util'

interface Props {
  cilindrada: keyof typeof CilindradaEnum
}

export default function Moto({ cilindrada }: Readonly<Props>) {
  const dispatch = useDispatch()
  const idLead = useSelector(selectRevisionMotoIdLead)

  const [selectedMarca, setSelectedMarca] = useState<number>(null)
  const [selectedModelo, setSelectedModelo] =
    useState<RevisionMotoGroupedModelo>(null)
  const [selectedMoto, setSelectedMoto] = useState<RevisionMotoModeloMoto>(null)
  const [resetModelo, setResetModelo] = useState<boolean>(false)
  const [resetYear, setResetYear] = useState<boolean>(false)

  const handleChangeMarca = async (marca: number) => {
    dispatch(revisionModelosReset())
    setSelectedMarca(marca)
    setResetModelo(true)
    setSelectedModelo(null)
    if (marca) dispatch(revisionModelos({ marca, cilindrada }))
  }

  const handleChangeModelo = async (modelo: RevisionMotoGroupedModelo) => {
    setSelectedModelo(modelo)
    setResetModelo(false)
    setSelectedMoto(
      modelo ? (modelo.motos.length === 1 ? modelo.motos[0] : null) : null
    )
    setResetYear(true)
  }

  const handleChangeYear = async (moto: RevisionMotoModeloMoto) => {
    setResetYear(false)
    setSelectedMoto(moto)
  }

  const { handleSubmit, register, errors } = useForm<PostRevisionLead>({
    mode: 'onSubmit',
    validationSchema: null,
  })

  const onSubmit = async (data: PostRevisionLead) => {
    dispatch(
      revisionLead({
        idRegistroLeadRevisionMoto: idLead,
        idSiteMarcaMoto: selectedMarca,
        idSiteModeloMoto: selectedModelo,
        idMoto: selectedMoto?.id_moto,
        fabricacion: selectedModelo?.motos[0].ano_fabricacion,
      })
    )
  }

  const handleOnGoBack = () => {
    dispatch(revisionLeadReset())
    dispatch(revisionMarcasReset())
    dispatch(revisionModelosReset())
    dispatch(revisionDatosReset())

    typeof window !== 'undefined' && window.history.go(-1)
  }
  const translatedCilindrada = translateCilindrada(cilindrada).toLowerCase()
  return (
    <div className={styles.revisionMotoMoto}>
      <div className={styles.revisionMotoMoto__text}>
        <h4>{`${t('revision_moto.detalles.title')}`}</h4>
        <div className={styles.revisionMotoMoto__text_specific}>
          {`${t('revision_moto.detalles.text', {
            cilindrada: translatedCilindrada,
          })}`}
        </div>
      </div>
      <form
        className={styles.revisionMotoMoto_form}
        onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.revisionMotoMoto_form_container}>
          <Marca
            register={register}
            errors={errors}
            cilindrada={cilindrada}
            handleChange={handleChangeMarca}
            labelAbove={false}
          />
          <Modelo
            register={register}
            errors={errors}
            handleChange={handleChangeModelo}
            reset={resetModelo}
            labelAbove={false}
          />
          {selectedModelo?.motos.length > 1 && (
            <Any
              register={register}
              motos={selectedModelo.motos}
              handleChange={handleChangeYear}
              reset={resetYear}
              errors={errors}
              labelAbove={false}
            />
          )}
        </div>
        <hr className={styles.revisionMotoMoto_form_separatorLine} />
        <SelectorFooterDesktop
          continueButton={true}
          onGoBack={handleOnGoBack}
        />
      </form>
    </div>
  )
}
