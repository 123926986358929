import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectRevisionMotoCilindrada,
  selectRevisionMotoMatricula,
  selectRevisionMotoPrecio,
} from '../../store/matricula/revisionMotoMatriculaSelectors'
import Precio from './precio/precio'
import { t } from '../../../../common/i18n'
import styles from './datosMarcaForm.module.scss'
import SpinnerWithText from '../../../../common/components/spinnerWithText/SpinnerWithText'
import { selectRevisionMotoMarcas } from '../../store/marcas/revisionMotoMarcasSelectors'
import { PostRevisionLead } from '../../api/postRevisionLead'
import { navigate } from 'gatsby'
import route from '../../../../common/utils/route'
import { FieldErrors } from 'react-hook-form/dist/types'
import { revisionDatosReset } from '../../store/matricula/revisionMotoMatriculaAction'
import Moto from './moto/moto'
import Proceso from './proceso/proceso'
import { revisionModelosReset } from '../../store/modelos/revisionMotoModelosAction'
import { revisionMarcasReset } from '../../store/marcas/revisionMotoMarcasAction'
import { revisionLeadReset } from '../../store/lead/revisionMotoLeadAction'
import WhiteBoxContainer from '../../../../shared/components/WhiteBoxContainer'
import Matricula from '../../../../shared/components/matricula/matricula'

export type DatosMarcaFormErrors = FieldErrors<PostRevisionLead>

export default function DatosMarcaForm() {
  const dispatch = useDispatch()
  const matricula = useSelector(selectRevisionMotoMatricula)
  const precio = useSelector(selectRevisionMotoPrecio)
  const cilindrada = useSelector(selectRevisionMotoCilindrada)
  const { loading, marcas, error } = useSelector(selectRevisionMotoMarcas)

  useEffect(() => {
    if (!matricula || !cilindrada) {
      navigate(route('moto.revisiones.index'))
    }
  }, [matricula, cilindrada, precio])

  const handleMatriculaChange = () => {
    dispatch(revisionLeadReset())
    dispatch(revisionMarcasReset())
    dispatch(revisionModelosReset())
    dispatch(revisionDatosReset())
  }

  return (
    <div className={styles.datosMarcaForm__container}>
      <div className={styles.datosMarcaForm__form_container}>
        <div className={styles.title}></div>
        {!marcas ? (
          <SpinnerWithText
            loading={loading}
            text={t('revisiones.step1_waiting')}
          />
        ) : (
          <div className={styles.datosMarcaForm__form_content}>
            <div className={styles.datosMarcaForm__form_content__matricula}>
              <WhiteBoxContainer>
                <Matricula
                  title={t('revision_moto.matricula.title')}
                  matricula={matricula}
                  onClick={handleMatriculaChange}
                />
              </WhiteBoxContainer>
            </div>
            <div className={styles.datosMarcaForm__form_content__precio}>
              <WhiteBoxContainer>
                <Precio precio={precio} cilindrada={cilindrada} />
              </WhiteBoxContainer>
            </div>
            <div className={styles.datosMarcaForm__form_content__moto}>
              <WhiteBoxContainer>
                <Moto cilindrada={cilindrada} />
              </WhiteBoxContainer>
            </div>
            <div className={styles.datosMarcaForm__form_content__proceso}>
              <WhiteBoxContainer>
                <Proceso />
              </WhiteBoxContainer>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
