import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { RevisionMotoModeloMoto } from '../modelo/modelo'
import { t } from '../../../../../../common/i18n'
import SpinnerWithText from '../../../../../../common/components/spinnerWithText/SpinnerWithText'
import { selectRevisionMotoModelos } from '../../../../store/modelos/revisionMotoModelosSelectors'
import { DatosMarcaFormErrors } from '../../DatosMarcaForm'
import InputDropDown from '../../../../../../shared/components/Input/InputDropDown'

interface Props {
  motos: RevisionMotoModeloMoto[]
  handleChange: (modelo: RevisionMotoModeloMoto) => void
  reset: boolean
  register: (instance: never) => void
  errors: DatosMarcaFormErrors
  labelAbove?: boolean
}

export default function Any({
  motos,
  handleChange,
  reset,
  register,
  errors,
  ...rest
}: Props) {
  const { loading } = useSelector(selectRevisionMotoModelos)
  const [selectedItem, setSelectedItem] = useState<number>(null)

  const onChange = (event) => {
    const moto = motos.find(
      (item: RevisionMotoModeloMoto) => item.id_moto === event.value
    ) as RevisionMotoModeloMoto
    setSelectedItem(event.value)
    handleChange(moto)
  }

  const additionalOptions = [
    {
      label: t('revisionesMoto.selector_ano.no_seguro'),
      value: t('revisionesMoto.selector_ano.no_seguro'),
    },
    {
      label: t('revisionesMoto.selector_ano.no_aparece'),
      value: t('revisionesMoto.selector_ano.no_aparece'),
    },
  ]

  const options = [
    ...(motos
      ?.sort((a, b) => b.ano_fabricacion - a.ano_fabricacion)
      .flatMap((moto) => ({
        label: moto.ano_fabricacion.toString(),
        value: moto.id_moto,
      })) || []),
    ...additionalOptions,
  ]
  return (
    <>
      {loading ? (
        <SpinnerWithText
          loading={loading}
          text={t('revisiones.step1_waiting')}
        />
      ) : (
        <InputDropDown
          placeholder={t('revision_moto.selecciona_ano')}
          labelId="selector-moto.fabricacion"
          name="fabricacion"
          formRef={register}
          errors={errors}
          options={options}
          onChange={onChange}
          required
        />
      )}
    </>
  )
}
