import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectRevisionMotoModelos } from '../../../../store/modelos/revisionMotoModelosSelectors'
import { t } from '../../../../../../common/i18n'
import { DatosMarcaFormErrors } from '../../DatosMarcaForm'
import styles from '../../datosMarcaForm.module.scss'
import InputDropDown from '../../../../../../shared/components/Input/InputDropDown'
import Select from 'react-select/base'
import {
  DropDownOption,
  OptionGrouped,
} from '../../../../../../shared/components/DropDown/DropDown'
import { GroupBase } from 'react-select'

export interface RevisionMotoModeloMoto {
  id_moto: string
  ano_fabricacion: number
}
export interface RevisionMotoGroupedModelo {
  modelo: string
  motos: RevisionMotoModeloMoto[]
}

interface Props {
  handleChange: (modelo: RevisionMotoGroupedModelo) => void
  reset: boolean
  register: (instance: never) => void
  errors: DatosMarcaFormErrors
  labelAbove?: boolean
}

export default function Modelo({
  handleChange,
  reset,
  register,
  errors,
  ...rest
}: Props) {
  const { loading, modelos, error } = useSelector(selectRevisionMotoModelos)
  const [selectedItem, setSelectedItem] = useState<number>(undefined)
  const innerRef =
    React.createRef<Select<DropDownOption, true, GroupBase<OptionGrouped>>>()

  useEffect(() => {
    if (reset) {
      innerRef.current.state.value = null
    }
  }, [reset])

  const groupedModelos: RevisionMotoGroupedModelo[] = modelos
    ? Object.values(
        modelos.reduce((acc, curr) => {
          if (!acc[curr.modelo]) {
            acc[curr.modelo] = { modelo: curr.modelo, motos: [] }
          }
          acc[curr.modelo].motos.push({
            id_moto: curr.id_moto,
            ano_fabricacion: curr.ano_fabricacion,
          })
          delete acc[curr.modelo].id_moto
          return acc
        }, {})
      )
    : []

  if (error) {
    return <>Error al cargar los modelos</>
  }

  const onChange = (event) => {
    const modelo = event
      ? (groupedModelos.find(
          (item: RevisionMotoGroupedModelo) => item.modelo === event.value
        ) as RevisionMotoGroupedModelo)
      : null
    handleChange(modelo)
  }

  const options = groupedModelos
    ?.sort((a, b) => a.modelo.localeCompare(b.modelo))
    .map((modelo: RevisionMotoGroupedModelo) => ({
      label: modelo.modelo.toString(),
      value: modelo.modelo,
    }))

  return (
    <div className={styles.datosMarcaForm__modelo}>
      <InputDropDown
        defaultSelected={selectedItem}
        disabled={options.length === 0}
        placeholder={
          loading
            ? t('revisiones.cargando')
            : t('revision_moto.selecciona_modelo')
        }
        labelId="selector-moto.modelo"
        name="idSiteModeloMoto"
        formRef={innerRef}
        errors={errors}
        options={options}
        onChange={onChange}
        required
        searchable={true}
      />
    </div>
  )
}
