import Layout from '../../../../../../src/common/components/Layout'
import LayoutSelector from '../../../../../../src/common/components/layouts/layoutSelector/LayoutSelector'
import React from 'react'
import { t } from '../../../../../../src/common/i18n'
import { SeoData } from '../../../../../../src/common/types/Seo'
import { graphql } from 'gatsby'
import DatosMarcaForm from '../../../../../../src/moto/revisiones/components/datos/DatosMarcaForm'
import FunnelSideBar from '../../../../../../src/common/components/funnelSideBar/FunnelSideBar'

interface Props {
  data: {
    seoData: SeoData
  }
}

const dataSteps = [
  {
    step: 1,
    name: 'formLabels.matricula',
  },
  {
    step: 2,
    name: 'revisionesMoto.tu_moto',
  },
  {
    step: 3,
    name: 'steps_cesta.tu_solicitud',
  },
]

export default function DatosMarcaRevisionMoto({ data }: Props) {
  return (
    <Layout seoData={data.seoData} selector={true}>
      <LayoutSelector
        customSteps={dataSteps}
        stepActive={2}
        mobileStep={2}
        totalSteps={3}
        title={t('revisionesMoto.revision')}
        subTitle={t('revisionesMoto.tu_moto')}>
        <DatosMarcaForm />
        <FunnelSideBar />
      </LayoutSelector>
    </Layout>
  )
}
export const pageQuery = graphql`
  query assistenteMarcaRevisionMotoQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
